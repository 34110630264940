import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import VueUeditorWrap from "vue-ueditor-wrap";
import { editorUploadUrl } from "@/utils/const";
import { startLoading, endLoading } from "@/utils/function";

export default {
    name: "other",
    // 页面组件
    components: {
        VueUeditorWrap,
    },
    data() {
        return {
            // ueditor 编辑器配置项
            myConfig: {
                // 设置编辑器为只读状态
                readonly:true,
                // 初始容器宽度
                initialFrameWidth: "100%",
                // 初始容器高度
                initialFrameHeight: 100,
                // 你的UEditor资源存放的路径,相对于打包后的index.html
                UEDITOR_HOME_URL: "./Editor/",
                // 编辑器不自动被内容撑高
                // autoHeightEnabled: false,
                // 如果需要上传功能,找后端小伙伴要服务器接口地址
                serverUrl: editorUploadUrl,
                // 关闭自动保存
                enableAutoSave: false,
                // 自定义工具栏，需要额外选项可以参考ueditor.config.js
                toolbars: [
                [
                    "inserttable",//表格
                    "source", //源代码
                    "bold", //加粗
                    "italic", //斜体
                    "underline", //下划线
                    "strikethrough", //删除线
                    "fontborder", //字符边框
                    "blockquote", //引用
                    "selectall", //全选
                    "horizontal", //分隔线
                    "removeformat", //清除格式
                    "unlink", //取消链接
                    "deletecaption", //删除表格标题
                    "inserttitle", //插入标题
                    "cleardoc", //清空文档
                    "insertcode", //代码语言
                    "fontfamily", //字体
                    "fontsize", //字号
                    "insertimage", //多图上传
                    "link", //超链接
                    "emotion", //表情
                    "spechars", //特殊字符
                    "searchreplace", //查询替换
                    "insertvideo", //视频
                    "justifyleft", //居左对齐
                    "justifyright", //居右对齐
                    "justifycenter", //居中对齐
                    "forecolor", //字体颜色
                    "insertorderedlist", //有序列表
                    "insertunorderedlist", //无序列表
                    "imageleft", //左浮动
                    "imageright", //右浮动
                    "attachment", //附件
                    "imagecenter", //居中
                    "lineheight", //行间距
                ],
                ],
            },
            // =======================================================
            // 右侧内容显示标识
            rightContentShow: true,
            // 报告题目列表
            questionList: [],
            // 报告状态
            report_status:0,
            // 模板标题
            previewTitle:'',
        };
    },
    /**
    * @name: 页面创建成功之后，获取预览模板数据
    * @author: lxy
    * @date: 2021-09-17
    */
    created()
    {
        const self = this;
        const contentString=window.sessionStorage.getItem('content');
        const content=JSON.parse(contentString)
        self.rightContentShow = true;
        self.questionList=content;
        self.getpreviewTitle();
        // console.log(self.questionList);
    },
    methods: 
    {
        /**
         * @name: 获取预览模板标题
         * @author: lxy
         * @date: 2021-09-07
         */
        getpreviewTitle()
        {
            var self=this;
            self.previewTitle=self.$route.query.name
        },

    },
};
