<template>
    <!-- 素材列表页 -->
    <div class="pdf">  
        <!-- 右侧模块 -->
        <div class="div" >
            <div v-if="rightContentShow" id="scrolldIV">
                <!-- 模板内容 -->
                <div v-if="report_status == 0">
                    <!-- 模板标题 -->
                     <h1 class="title">{{previewTitle}}</h1>
                    <!-- 题目列表 -->
                    <div v-for="(item,index) in questionList" :key="index" class="report-item" >
                        <!-- 索引 + title + 分数 -->
                        <div class="title-index">
                            <span class ="left span-answer" v-if="!item.must_answer" >(非必答)</span>
                            <span class ="left span-index" >{{index + 1}}：</span>
                            <span v-html="item.title" class="span-title"></span>
                            <span class="right span-score" >{{item.score}} 分</span>
                        </div>
                        <!-- ueditor编辑器 -->
                        <!-- 图片，文本 -->
                        <el-input
                            type="textarea"
                            :rows="6"
                            placeholder="请输入内容"
                            v-model="item.content">
                        </el-input>
                        <!-- 虚拟 -->
                        <div v-if="item.type == 'xuni'">
                            <div class="table" v-for="(item1,index1) in item.value" :key="index1" >
                                    <div class="table-item-left">
                                        {{item1[0]}}
                                    </div>
                                    <div class="table-item-right">
                                    {{item1[1]}}
                                </div>     
                            </div>
                        </div>
                        <!-- 表格 -->
                        <div v-if="item.type == 'table'" class="tables">
                            <el-row v-for="(trItem, trIndex) in item.table" :key="trIndex">
                                <!-- @ 背景 #FFFFFF input -->
                                <!-- # 背景 #FAF8CF input -->
                                <!-- '' 背景 #D3D3D3 不可编辑 -->
                                <el-col :style="{ width: (100 / trItem.length) + '%' }" v-for="(tdItem, tdIndex) in trItem" :key="tdIndex">
                                
                                    <div v-if="tdItem && tdItem.substring(0, 1) == '@'">
                                        <el-input  :value="trItem[tdIndex].substring(1)" placeholder="请输入内容" ></el-input>
                                    </div>
                                     <div v-else-if="tdItem && tdItem.substring(0, 1) == '#'" class="yellow">
                                        <el-input  :value="trItem[tdIndex].substring(1)" placeholder="请输入内容" ></el-input>
                                    </div>
                                    <div v-else>
                                        <div v-if="tdItem">
                                            <el-input  v-model="trItem[tdIndex]" :value="trItem[tdIndex]" disabled></el-input>
                                        </div>
                                        <div v-else>
                                            <el-input v-model="trItem[tdIndex]" :value="trItem[tdIndex]" disabled></el-input>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="recover-button">
                    <h3>暂无数据~</h3><br>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" >
    .el-dialog__body{
        padding: 30px 20px ;
        padding-top: 5px !important;
        color: #606266;
        font-size: 14px;
        word-break: break-all;
        padding-bottom: 30px;
    }
    .el-table .brown {
        background-color: #FAFAFA;
    }
    .el-input.is-disabled .el-input__inner {
        border-color: #E4E7ED;
        cursor: not-allowed;
        background-color:#9ba7a9;
        color:#FFF;
    }
</style>
<style lang="scss" scoped>
    @import "@/assets/css/teacher/report/preview.scss";
</style>

<script>
    import preview from "@/assets/js/teacher/report/preview.js";
    export default {
        ...preview
};
</script>

